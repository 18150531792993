import { generateImageUrl } from "~/utils/generateImageUrl.js";

export const WINDMILL_DRAWING_URL = generateImageUrl(
  "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/windmill-drawing-2.png",
  {
    width: 1024,
    height: 1024,
  }
);

export const CLOUD_DRAWING_1_URL = generateImageUrl(
  "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/cloud-drawing-1.png",
  {
    width: 204,
    height: 134,
  }
);

export const CLOUD_DRAWING_2_URL = generateImageUrl(
  "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/cloud-drawing-2.png",
  {
    width: 202,
    height: 179,
  }
);

export const SOC_LOGO_URL = generateImageUrl(
  "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/soc-logo.png",
  {
    width: 400,
    height: 400,
  }
);
